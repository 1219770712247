import React, { useEffect, useRef, useState } from "react";
import { FitAddon } from '@xterm/addon-fit';
import { ITerminalAddon, Terminal } from "@xterm/xterm";
import ConnectProps from "./ConnectProps";
import { RouteComponentProps } from "react-router";
import DreamscapeApiFactory from "../dreamscape-api/DreamscapeApiFactory";
import { createRoot } from "react-dom/client";
import { DefaultApi, Session, SessionState, StartSessionRequest } from "../dreamscape-api/generated-src";
import { ContentLayout, Flashbar, TopNavigation } from "@amzn/awsui-components-react";
import { getAppSetting } from "../config/AppSettings";
import {
  DreamscapeMessage,
  PTYSessionResize, S3Download,
  WebSocketCommand
} from "@amzn/coral_com-amazonaws-console-dreamscape-model";
import { getMidwayJwtToken } from "../auth/MidwayJwtToken";
import { FlashbarProps } from "@amzn/awsui-components-react/polaris/flashbar/interfaces";

class WebSocketAddon implements ITerminalAddon {
  private _terminal: Terminal | undefined;
  private _webSocket: WebSocket | undefined;
  private _sessionState: string;
  private _activated: boolean;
  private _connectInterval: number | undefined;
  readonly _sessionId: string;
  readonly _webSocketBaseUrl: string;
  readonly _dreamscapeApi: DefaultApi;
  readonly _setSessionName: React.Dispatch<React.SetStateAction<string>>
  readonly _setSessionState: React.Dispatch<React.SetStateAction<string>>

  public constructor(sessionId: string,
                     setSessionName: React.Dispatch<React.SetStateAction<string>>,
                     setSessionState: React.Dispatch<React.SetStateAction<string>>) {
    this._activated = false;
    this._sessionId = sessionId;
    this._sessionState = "";
    this._webSocketBaseUrl = `wss://${getAppSetting('wsUrl')}`
    this._dreamscapeApi = DreamscapeApiFactory();
    this._setSessionName = setSessionName;
    this._setSessionState = setSessionState;
  }

  public activate(terminal: Terminal): void {
    if (this._activated) {
      return;
    }
    this._activated = true;
    this._terminal = terminal;
    if (this._webSocket) {
      this._webSocket.close();
    }
    this._webSocket = undefined;
    this.scheduleConnect();
    this.schedulePing();
  }

  dispose(): void {
    if (this._connectInterval) {
      clearInterval(this._connectInterval);
    }
  }

  setTitles(session: Session) {
    const sessionName = session.sessionName || session.sessionId || this._sessionId;
    const sessionState = this._sessionState;
    this._setSessionName(sessionName);
    this._setSessionState(sessionState);
    document.title = `Dreamscape - ${sessionName} - ${sessionState}`;
  }

  showError = (errorMessage: string) => {
    console.log(errorMessage);
  }

  isOpen = () => {
    return this._webSocket !== undefined && this._webSocket.readyState === WebSocket.OPEN;
  }

  isSessionActive = () => {
    return this._sessionState === SessionState.Active;
  }

  scheduleConnect = () => {
    setTimeout(() => {
      this.connect();
    }, 1000);
  }

  schedulePing = () => {
    this._connectInterval = setInterval(() => {
      if (!this.sendPing()) {
        clearInterval(this._connectInterval);
      }
    }, 10000);
  }

  sendPing = (): boolean => {
    // sending ping only if already connected
    if (!this.isOpen()) {
      return true;
    }
    const pingMessage = DreamscapeMessage.fromJson(
      {
        action: WebSocketCommand.PING,
        sessionId: this._sessionId,
        data: JSON.stringify({
          sessionId: this._sessionId
        })
      }
    )
    this._webSocket?.send(JSON.stringify(DreamscapeMessage.toJson(pingMessage)));
    return true;
  }

  sendPong = () => {
    if (!this.isOpen()) {
      return;
    }
    this._webSocket?.send(JSON.stringify({
      action: WebSocketCommand.PONG,
      sessionId: this._sessionId
    }));
  }

  sendResize = () => {
    if (!this.isOpen()) {
      return
    }
    const ptySessionResize = PTYSessionResize.clone({
      sessionId: this._sessionId,
      cols: this._terminal?.cols,
      rows: this._terminal?.rows
    })
    const resizeMessage = DreamscapeMessage.fromJson({
      action: WebSocketCommand.RESIZE,
      sessionId: this._sessionId,
      data: JSON.stringify(PTYSessionResize.toJson(ptySessionResize))
    });
    this._webSocket?.send(JSON.stringify(DreamscapeMessage.toJson(resizeMessage)));
  }

  sendNotify = (bucket: String, key: String): void => {
    if (!this.isOpen()) {
      return
    }
    const s3Download: S3Download = S3Download.clone({
      bucket: bucket.toString(),
      key: key.toString()
    })
    const notificationMessage = DreamscapeMessage.fromJson({
      action: WebSocketCommand.FILE_UPLOAD_NOTIFY,
      sessionId: this._sessionId,
      data: JSON.stringify(S3Download.toJson(s3Download))
    });
    this._webSocket?.send(JSON.stringify(DreamscapeMessage.toJson(notificationMessage)));
  }

  sendReplay = () => {
    if (!this.isOpen()) {
      return
    }
    const replayMessage = DreamscapeMessage.fromJson({
      action: WebSocketCommand.REPLAY,
      sessionId: this._sessionId
    });
    this._terminal?.clear();
    this._webSocket?.send(JSON.stringify(DreamscapeMessage.toJson(replayMessage)));
  }

  connect = () => {
    const me = this;
    me._dreamscapeApi.getSession({ sessionId: me._sessionId }).then(resp => {
      if (resp.data.error) {
        me.showError(resp.data.error);
        return;
      }
      const session = resp.data.session!;
      // connecting only if session is not closed
      me._sessionState = session.sessionState || SessionState.New;
      me.setTitles(session);
      if (me._sessionState === SessionState.Closed) {
        return;
      }
      const webSocketSetup = (federationData: string | undefined = undefined) => {
        getMidwayJwtToken().then(jwtToken => {
          me._webSocket = new WebSocket(`${me._webSocketBaseUrl}?sessionId=${me._sessionId}&authorizer=${jwtToken}`);
          me._webSocket.addEventListener("open", function (e: Event) {
            const startSessionRequest: StartSessionRequest = {
              sessionId: me._sessionId
            };
            if (federationData !== undefined) {
              startSessionRequest.federationData = federationData;
            }
            me._dreamscapeApi.startSession(startSessionRequest).then(resp => {
              if (resp.data.error) {
                me.showError(resp.data.error);
                return;
              }
              // immediately send ping once websocket connected, this takes session to grace mode
              me.sendPing();
              me._terminal?.clear();
            });
          });
          me._webSocket.addEventListener("message", (e: MessageEvent) => {
            const payload = DreamscapeMessage.fromJson(JSON.parse(e.data));
            const action = payload.action;
            const data = payload.data;
            if (payload.sessionId != me._sessionId) return;
            switch (action) {
              case WebSocketCommand.PING:
                me.sendPong();
                break;
              case WebSocketCommand.PONG:
                if (data) {
                  const sessionPong = JSON.parse(data);
                  if (sessionPong.statusCode != 200) {
                    break;
                  }
                  const prevSessionState = me._sessionState;
                  const sessionState = sessionPong.sessionState;
                  me._sessionState = sessionState;
                  me.setTitles(session);
                  switch (sessionState) {
                    case SessionState.Active:
                      // clearing flash
                      if (prevSessionState !== sessionState) {
                        this.sendResize();
                        this.sendReplay();
                      }
                      break;
                    case SessionState.Closed:
                      this.disconnect();
                      break;
                    case SessionState.Failed:
                      this.disconnect();
                      let errorMessage = "Session has failed";
                      if (sessionPong.sessionStateReason !== null && sessionPong.sessionStateReason !== "") {
                        errorMessage = sessionPong.sessionStateReason;
                      }
                      break;
                  }
                }
                break;
              case WebSocketCommand.DATA:
                if (data && me.isSessionActive()) {
                  me._terminal?.write(atob(data));
                }
                break;
              case WebSocketCommand.REPLAY:
                me._terminal?.clear();
                if (data) {
                  me._terminal?.write(atob(data));
                }
            }
          });
          me._webSocket.addEventListener("close", (e: Event) => {
            me.scheduleConnect();
          });
          me._webSocket.addEventListener("error", (e: Event) => {
          });
        });
      }
      if (session.federationAccountEmail && session.federationRoleName) {
        // @ts-ignore
        if (typeof document.getFederationAccountId !== "undefined") {
          // @ts-ignore
          document.getFederationAccountId(session.fabric, session.availabilityZone, session.federationAccountEmail).then((accountId: string) => {
            // @ts-ignore
            if (typeof document.getAssumeRoleCredentials !== "undefined") {
              // @ts-ignore
              document.getAssumeRoleCredentials(session.fabric, session.availabilityZone, accountId, session.federationRoleName).then((assumeRoleResult: string) => {
                webSocketSetup(assumeRoleResult);
              }).catch(() => {
                me.showError(`Dreamscape/Isengard federation has failed`);
              });
            } else {
              me.showError(`Dreamscape/Isengard federation requires user script installed`);
            }
          })
          .catch(() => {
            me.showError(`Unable to find AWS account ID for ${session.federationAccountEmail}`);
          })
        } else {
          me.showError(`Dreamscape/Isengard federation requires user script installed`);
        }
      } else {
        webSocketSetup();
      }
    }).catch(() => {
      this.scheduleConnect();
    });
  }

  disconnect = () => {
    if (this._webSocket !== undefined) {
      this._webSocket.close(1000, "exit");
      this._webSocket = undefined;
    }
  }

  write = (data: string) => {
    if (this.isOpen() && this.isSessionActive()) {
      const dataMessage = DreamscapeMessage.fromJson({
        action: WebSocketCommand.DATA,
        sessionId: this._sessionId,
        data: btoa(data)
      })
      this._webSocket?.send(JSON.stringify(DreamscapeMessage.toJson(dataMessage)));
    }
  }
}

export default function Connect(props: RouteComponentProps<ConnectProps>) {
  let [flash, setFlash] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [sessionName, setSessionName] = useState<string>("");
  const [sessionState, setSessionState] = useState<string>(SessionState.New);
  const terminalRef = useRef<HTMLDivElement>(null)
  const [terminalInstance, setTerminalInstance] = useState<Terminal | null>(null)

  const topnav = createRoot(document.getElementById('topnav')!);
  topnav.render(<TopNavigation
      i18nStrings={{
        searchIconAriaLabel: 'Search',
        searchDismissIconAriaLabel: 'Close search',
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'DS'
      }}
      identity={{
        href: '',
        title: `${sessionName}`
      }}
      utilities={[]}
    />
  );

  useEffect(() => {
    const connectProps: ConnectProps = props.match.params;
    const sessionId = connectProps.sessionId;

    const fitAddon = new FitAddon();
    document.title = `Dreamscape`;
    window.onresize = (event) => {
      fitAddon.fit();
    };
    const webSocketAddon = new WebSocketAddon(sessionId, setSessionName, setSessionState);
    const instance = new Terminal({
      fontFamily: 'operator mono,SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace',
      fontSize: 14,
      theme: {
        background: '#101420',
      },
      cursorStyle: 'block',
      cursorBlink: false,
    });
    instance.loadAddon(fitAddon);
    instance.loadAddon(webSocketAddon);
    instance.onData((data: string) => {
      webSocketAddon.write(data);
    });
    instance.onResize((event: { cols: number; rows: number }) => {
      webSocketAddon.sendResize();
    });
    if (terminalRef.current) {
      // Mount terminal
      instance.open(terminalRef.current);
      instance.focus();
    }
    setTerminalInstance(instance);

    fitAddon.fit();
    return () => {
      instance.dispose();
      setTerminalInstance(null);
    }
  }, []);

  return (
    <ContentLayout
      notifications={
        <Flashbar
          items={flash}
        />
      }
    >
      <div style={{ height: '100%' }} ref={terminalRef}/>
    </ContentLayout>
  );
}
