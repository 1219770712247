import React from "react";
import { AppLayout } from "@amzn/awsui-components-react";
import SideNav from "./SideNav";
import { createRoot } from "react-dom/client";
import TopNav from "./TopNav";

export default function Home() {
  const topnav = createRoot(document.getElementById('topnav')!);
  topnav.render(<TopNav/>);
  return (
    <AppLayout
      navigationWidth={200}
      navigation={<SideNav/>}
      headerSelector={"#topnav"}
      toolsHide={true}
      content={<></>}
    />
  );
}
