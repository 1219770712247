import React, { useId } from "react";
import { FlashbarProps } from "@amzn/awsui-components-react";
import { v4 } from "uuid";

export default function showError(setFlash: React.Dispatch<React.SetStateAction<FlashbarProps.MessageDefinition[]>>, message: FlashbarProps.MessageDefinition, clearPrevious: boolean = false) {
  setFlash((prevState: FlashbarProps.MessageDefinition[]) => {
    const newState: FlashbarProps.MessageDefinition[] = (clearPrevious) ? [] : prevState;
    if (!message.id) {
      message.id = v4();
    }
    if (!message.type) {
      message.type = "error";
    }
    if (message.dismissible === undefined) {
      message.dismissible = true;
    }
    if (message.dismissible) {
      message.onDismiss = () => {
        setFlash(items =>
          items.filter(item => item.id !== message.id)
        );
      };
    }
    newState.push(message);
    return newState;
  });
}
