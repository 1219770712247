import {
  AppLayout,
  Box,
  Button, Checkbox, CheckboxProps, ColumnLayout, Container,
  ContentLayout,
  Flashbar, Form, FormField, Grid,
  Header, Input, InputProps,
  Link, Modal, NonCancelableCustomEvent, RadioGroup, RadioGroupProps, Select, SelectProps,
  SpaceBetween,
  Table
} from "@amzn/awsui-components-react";
import SideNav from "./SideNav";
import React, { useEffect, useState } from "react";
import { FlashbarProps } from "@amzn/awsui-components-react/polaris/flashbar/interfaces";
import { GetBMCInfoRequest, Session } from "../dreamscape-api/generated-src";
import DreamscapeApiFactory from "../dreamscape-api/DreamscapeApiFactory";
import { createRoot } from "react-dom/client";
import TopNav from "./TopNav";
import { GetAvailabilityZonesFromLocalStorage, GetFabricsFromLocalStorage } from "../utils/dreamscape";
import { hideLoading, showLoading } from "./Loading";
import { getFederationAccountEmail, isEC2, isValidCAZ, isValidMAC } from "./Create";
import { BaseKeyDetail } from "@amzn/awsui-components-react/polaris/internal/events";
import { MAC, parseMAC } from "@ctrl/mac-address";
import showError from "./Error";

const BMC_CONNECT = "bmc";
const AZ_CONNECT = "az-fabric";
const AZ_FABRIC_SESSION_TYPES: SelectProps.Option[] = [
  { label: "Shell", value: "shell", description: "Launch shell to execute arbitrary commands" },
];
const BMC_SPECIFIC_SESSION_TYPES: SelectProps.Option[] = [
  { label: "Inception", value: "inception", description: "Launch Inception to selected BMC" },
  { label: "SOL", value: "sol", description: "Launch SOL session to selected BMC" }
]
const BMC_SESSION_TYPES: SelectProps.Option[] = AZ_FABRIC_SESSION_TYPES.concat(BMC_SPECIFIC_SESSION_TYPES);
const ISENGARD_USER_SCRIPT = "https://code.amazon.com/packages/ConsoleDreamscapeIsengardFederation/blobs/mainline/--/configuration/ConsoleDreamscapeIsengardFederation.user.js?raw=1";

interface BMCAttr {
  label: string
  value: string
  readOnly: boolean
}

interface BMCAttrs {
  sessionType: BMCAttr
  macAddress: BMCAttr
  hardwareId: BMCAttr
  assetId: BMCAttr
  ipAddress: BMCAttr
  ipAddressOverride: BMCAttr
  ipAddressOverrideReason: BMCAttr
  availabilityZone: BMCAttr
  fabric: BMCAttr
  roleName: BMCAttr
  caz: BMCAttr
}

const getBmcAttrs = (connectType: string): BMCAttrs => {
  let sessionTypes = BMC_SESSION_TYPES;
  if (connectType === BMC_CONNECT) {
    sessionTypes = BMC_SESSION_TYPES;
  } else if (connectType === AZ_CONNECT) {
    sessionTypes = AZ_FABRIC_SESSION_TYPES;
  }
  return {
    "sessionType": {
      label: "Session Type",
      value: sessionTypes[0].value || "shell",
      readOnly: false
    },
    "macAddress": {
      label: "MAC Address",
      value: "",
      readOnly: connectType !== BMC_CONNECT
    },
    "hardwareId": {
      label: "Hardware ID",
      value: "",
      readOnly: connectType !== BMC_CONNECT
    },
    "assetId": {
      label: "Asset ID",
      value: "",
      readOnly: connectType !== BMC_CONNECT
    },
    "ipAddress": {
      label: "IP Address",
      value: "",
      readOnly: true
    },
    "ipAddressOverride": {
      label: "IP Address",
      value: "",
      readOnly: true
    },
    "ipAddressOverrideReason": {
      label: "Reason",
      value: "",
      readOnly: connectType !== BMC_CONNECT
    },
    "availabilityZone": {
      label: "Availability Zone",
      value: "",
      readOnly: connectType === BMC_CONNECT
    },
    "fabric": {
      label: "Fabric",
      value: "",
      readOnly: connectType === BMC_CONNECT
    },
    "roleName": {
      label: "",
      value: localStorage.getItem("roleName") || "",
      readOnly: false
    },
    "caz": {
      label: "Contingent Auth (Link to TT/SIM/MCM)",
      value: "",
      readOnly: false
    }
  }
}

export default function Sessions() {
  let [flash, setFlash] = useState<FlashbarProps.MessageDefinition[]>([]);
  let [sessions, setSessions] = useState<Session[]>([]);
  let [showConnectModal, setShowConnectModal] = useState<boolean>(false);
  let [connectFormType, setConnectFormType] = useState<string>(BMC_CONNECT);
  let [searchDisabled, setSearchDisabled] = useState<boolean>(false);
  let [connectDisabled, setConnectDisabled] = useState<boolean>(false);
  let [sessionTypes, setSessionTypes] = useState<SelectProps.Option[]>([]);
  let [bmcAttrs, setBmcAttrs] = useState<BMCAttrs>(getBmcAttrs(BMC_CONNECT));
  let [ipAddressOverrideEnabled, setIpAddressOverrideEnabled] = useState<boolean>(false);
  let [federationDisabled, setFederationDisabled] = useState<boolean>(true);
  let [sessionTableLoading, setSessionTableLoading] = useState<boolean>(false);

  const availabilityZones = GetAvailabilityZonesFromLocalStorage()
  .filter((az) => az.name !== undefined && az.name!.length > 3)
  .sort((az1, az2) => parseInt(az1.name!.substring(3)) - parseInt(az2.name!.substring(3)))
  .map((it) => {
    return { value: it.name, label: it.name }
  });
  const fabrics = GetFabricsFromLocalStorage().sort().map((it) => {
    return { value: it, label: it };
  });
  let azSessionTypeValues = AZ_FABRIC_SESSION_TYPES.map((st) => st.value);
  const azFabricDisabled = sessionTypes.map((st) => st.value).filter((st) => azSessionTypeValues.indexOf(st) >= 0).length == 0;

  const loadActiveSessions = () => {
    setSessionTableLoading(true);
    DreamscapeApiFactory().listActiveSessions().then(resp => {
      setSessionTableLoading(false);
      const sessionList = resp.data.sessions || [];
      setSessions(sessionList);
    }).catch(() => {
      showError(setFlash, { content: `Error occurred getting list of open sessions... Make sure you are connected to corp fabric or connected via VPN` });
      setSessionTableLoading(false);
    });
  };

  const changeConnectFormType = (value: string) => {
    if (value === BMC_CONNECT) {
      setConnectFormType(BMC_CONNECT);
      setSearchDisabled(false);
      setConnectDisabled(true);
      setSessionTypes(BMC_SESSION_TYPES);
      setIpAddressOverrideEnabled(false);
      setBmcAttrs(getBmcAttrs(BMC_CONNECT));
    } else if (value === AZ_CONNECT) {
      setConnectFormType(AZ_CONNECT);
      setSearchDisabled(true);
      setConnectDisabled(false);
      setIpAddressOverrideEnabled(false);
      setSessionTypes(AZ_FABRIC_SESSION_TYPES);
      setBmcAttrs(getBmcAttrs(AZ_CONNECT));
    }
  }

  const showConnectForm = () => {
    setConnectDisabled(true);
    setSearchDisabled(false);
    setConnectFormType(BMC_CONNECT);
    setSessionTypes(BMC_SESSION_TYPES);
    setIpAddressOverrideEnabled(false);
    setBmcAttrs(getBmcAttrs(BMC_CONNECT));
    setShowConnectModal(true);
  }

  const getBmcInfo = () => {
    const macAddress = bmcAttrs.macAddress.value;
    const hardwareId = bmcAttrs.hardwareId.value;
    const assetId = bmcAttrs.assetId.value;
    if ([macAddress, hardwareId, assetId].every(v => v === undefined || v === "")) {
      return;
    }
    const getBMCInfoRequest: GetBMCInfoRequest = {};
    if (macAddress !== undefined && macAddress !== "") {
      getBMCInfoRequest.macAddress = macAddress;
    }
    if (hardwareId !== undefined && hardwareId !== "") {
      getBMCInfoRequest.hardwareId = hardwareId;
    }
    if (assetId !== undefined && assetId !== "") {
      getBMCInfoRequest.assetId = assetId;
    }
    showLoading();
    DreamscapeApiFactory().getBMCInfo(getBMCInfoRequest).then(resp => {
      hideLoading();
      if (resp.data.error) {
        showError(setFlash, {
          content: resp.data.error,
        });
        if (resp.data.errors) {
          resp.data.errors.forEach((e: string) => {
            showError(setFlash, {
              content: e,
            });
          })
        }
        return;
      }
      const bmc = resp.data.bmc!;
      if (bmc.hardwareId) {
        const newBmcAttrs = getBmcAttrs(BMC_CONNECT);
        type bmcAttrKey = keyof typeof newBmcAttrs;
        for (let k in newBmcAttrs) {
          if (bmc.hasOwnProperty(k)) {
            newBmcAttrs[k as bmcAttrKey].value = bmc[k];
          }
        }
        // resetting IP override values
        newBmcAttrs.ipAddressOverride.value = bmcAttrs.ipAddress.value;
        newBmcAttrs.ipAddressOverrideReason.value = "";
        setConnectDisabled(false);
        setFederationDisabled(!isEC2(newBmcAttrs.fabric.value));
        setBmcAttrs(newBmcAttrs);
      }
    }).catch(reason => {
      hideLoading();
      //showError(CONNECT_MODAL, "Error occurred getting BMC information...");
      console.error(reason);
    });
  }

  const validate = (): string[] => {
    return [];
  }

  const create = () => {
    type bmcAttrKey = keyof typeof bmcAttrs;
    const searchParamsObj = {};
    for (let k in bmcAttrs) {
      const val = bmcAttrs[k as bmcAttrKey].value;
      if (val) {
        searchParamsObj[k] = val;
      }
    }
    const searchParams = new URLSearchParams(searchParamsObj).toString()
    window.open(`/create?${searchParams}`, '_blank');
  }

  useEffect(() => {
    const topnav = createRoot(document.getElementById('topnav')!);
    topnav.render(<TopNav/>);
    // @ts-ignore
    if (typeof document.getFederationAccountId === "undefined" || typeof document.getAssumeRoleCredentials === "undefined") {
      showError(setFlash, {
        type: "warning",
        content: (
          <>
            Isengard federation user script is missing in your browser. Install <Link external
                                                                                      href={ISENGARD_USER_SCRIPT}>here</Link>
          </>
        ),
        dismissible: false
      })
    }
    loadActiveSessions();
  }, []);

  return (
    <AppLayout
      navigationWidth={200}
      navigation={<SideNav/>}
      headerSelector={"#topnav"}
      toolsHide={true}
      content={
        <ContentLayout
          header={
            <Header
              variant="h1"
              info={<Link variant="info">Info</Link>}
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant={"normal"}
                    iconName={"refresh"}
                    onClick={() => {
                      loadActiveSessions();
                    }}
                  />
                  <Button
                    variant={"primary"}
                    onClick={() => {
                      showConnectForm();
                    }}
                  >New Session</Button>
                </SpaceBetween>}
            >
              Sessions
            </Header>
          }
          notifications={
            <Flashbar
              items={flash}
            />
          }
        >
          <Modal
            visible={showConnectModal}
            header={"New Session"}
            size={"large"}
            onDismiss={() => {
              setShowConnectModal(false);
            }}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant={"primary"}
                    disabled={connectDisabled}
                    onClick={() => {
                      if (validate()) {
                        create();
                      }
                    }}>
                    Connect
                  </Button>
                </SpaceBetween>
              </Box>
            }
          >
            <Form>
              <ColumnLayout columns={2}>
                <div>
                  <RadioGroup
                    value={connectFormType}
                    onChange={(event: NonCancelableCustomEvent<RadioGroupProps.ChangeDetail>) => changeConnectFormType(event.detail.value)}
                    items={[
                      { value: BMC_CONNECT, label: "BMC", description: "Limited access to single BMC" },
                    ]}
                  />
                  <Container>
                    <SpaceBetween size={"s"}>
                      <FormField
                        label={bmcAttrs.macAddress.label}>
                        <Input
                          onChange={
                            (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                              let macAddress: MAC | undefined = undefined;
                              try {
                                macAddress = parseMAC(event.detail.value);
                              } catch (e) {
                                macAddress = undefined;
                              }
                              const newBmcAttrs = getBmcAttrs(connectFormType);
                              if (macAddress) {
                                newBmcAttrs.macAddress.value = macAddress.toString({ zeroPad: true }).toUpperCase();
                              } else {
                                newBmcAttrs.macAddress.value = event.detail.value;
                              }
                              setSearchDisabled(false);
                              setConnectDisabled(true);
                              setBmcAttrs(newBmcAttrs);
                            }
                          }
                          onKeyUp={
                            (event: CustomEvent<BaseKeyDetail>) => {
                              if (event.detail.keyCode == 13 && isValidMAC(bmcAttrs.macAddress.value)) {
                                getBmcInfo();
                              }
                            }
                          }
                          value={bmcAttrs.macAddress.value}
                          type={"search"}
                          readOnly={bmcAttrs.macAddress.readOnly}
                          invalid={!isValidMAC(bmcAttrs.macAddress.value)}
                        />
                      </FormField>
                      <FormField
                        label={bmcAttrs.hardwareId.label}>
                        <Input
                          onChange={
                            (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                              const newBmcAttrs = getBmcAttrs(connectFormType);
                              newBmcAttrs.hardwareId.value = event.detail.value;
                              setSearchDisabled(false);
                              setConnectDisabled(true);
                              setBmcAttrs(newBmcAttrs);
                            }
                          }
                          onKeyUp={
                            (event: CustomEvent<BaseKeyDetail>) => {
                              if (event.detail.keyCode == 13) {
                                getBmcInfo();
                              }
                            }
                          }
                          value={bmcAttrs.hardwareId.value}
                          type={"search"}
                          readOnly={bmcAttrs.hardwareId.readOnly}
                        />
                      </FormField>
                      <FormField
                        label={bmcAttrs.assetId.label}>
                        <Input
                          onChange={
                            (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                              const newBmcAttrs = getBmcAttrs(connectFormType);
                              newBmcAttrs.assetId.value = event.detail.value;
                              setSearchDisabled(false);
                              setConnectDisabled(true);
                              setBmcAttrs(newBmcAttrs);
                            }
                          }
                          onKeyUp={
                            (event: CustomEvent<BaseKeyDetail>) => {
                              if (event.detail.keyCode == 13) {
                                getBmcInfo();
                              }
                            }
                          }
                          value={bmcAttrs.assetId.value}
                          type={"search"}
                          readOnly={bmcAttrs.assetId.readOnly}
                        />
                      </FormField>
                      <FormField
                        label={bmcAttrs.ipAddress.label}>
                        <Grid>
                          <Input
                            onChange={
                              (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                                const newBmcAttrs = { ...bmcAttrs };
                                newBmcAttrs.ipAddressOverride.value = event.detail.value;
                                setBmcAttrs(newBmcAttrs);
                              }
                            }
                            value={(ipAddressOverrideEnabled) ? bmcAttrs.ipAddressOverride.value : bmcAttrs.ipAddress.value}
                            type={"text"}
                            readOnly={!ipAddressOverrideEnabled}
                            disabled={!ipAddressOverrideEnabled}
                          />
                          <Checkbox
                            disabled={connectDisabled || connectFormType !== BMC_CONNECT}
                            checked={ipAddressOverrideEnabled}
                            onChange={
                              (event: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>) => {
                                const newBmcAttrs = { ...bmcAttrs };
                                newBmcAttrs.ipAddressOverride.value = newBmcAttrs.ipAddress.value;
                                newBmcAttrs.ipAddressOverrideReason.value = "";
                                setIpAddressOverrideEnabled(event.detail.checked);
                                setBmcAttrs(newBmcAttrs);
                              }
                            }>
                            Override
                          </Checkbox>
                        </Grid>
                      </FormField>
                      <FormField
                        label={bmcAttrs.ipAddressOverrideReason.label}>
                        <Input
                          onChange={
                            (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                              const newBmcAttrs = { ...bmcAttrs };
                              newBmcAttrs.ipAddressOverrideReason.value = event.detail.value;
                              setBmcAttrs(newBmcAttrs);
                            }
                          }
                          value={bmcAttrs.ipAddressOverrideReason.value}
                          type={"text"}
                          readOnly={!ipAddressOverrideEnabled}
                        />
                      </FormField>
                      <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button
                            variant={"primary"}
                            iconName={"search"}
                            disabled={searchDisabled}
                            onClick={() => {
                              getBmcInfo();
                            }}>
                            Search
                          </Button>
                        </SpaceBetween>
                      </Box>
                    </SpaceBetween>
                  </Container>
                </div>
                <div>
                  <RadioGroup
                    value={connectFormType}
                    onChange={(event: NonCancelableCustomEvent<RadioGroupProps.ChangeDetail>) => changeConnectFormType(event.detail.value)}
                    items={[
                      {
                        value: AZ_CONNECT,
                        label: "Availability Zone/Fabric",
                        description: "Access to every BMC in the selected zone/fabric",
                        disabled: azFabricDisabled
                      }
                    ]}
                  />
                  <Container>
                    <SpaceBetween size={"s"}>
                      <FormField
                        label={bmcAttrs.availabilityZone.label}>
                        <Select
                          selectedOption={availabilityZones.find((o) => o.value === bmcAttrs.availabilityZone.value) || null}
                          onChange={
                            (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
                              const newBmcAttrs = { ...bmcAttrs };
                              newBmcAttrs.availabilityZone.value = event.detail.selectedOption.value || availabilityZones[0].value || "";
                              setBmcAttrs(newBmcAttrs);
                            }
                          }
                          options={availabilityZones}
                          disabled={bmcAttrs.availabilityZone.readOnly}
                        />
                      </FormField>
                      <FormField
                        label={bmcAttrs.fabric.label}>
                        <Select
                          selectedOption={fabrics.find((o) => o.value === bmcAttrs.fabric.value) || null}
                          onChange={
                            (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
                              const newBmcAttrs = { ...bmcAttrs };
                              newBmcAttrs.fabric.value = event.detail.selectedOption.value || fabrics[0].value || "";
                              setFederationDisabled(!isEC2(newBmcAttrs.fabric.value));
                              setBmcAttrs(newBmcAttrs);
                            }
                          }
                          options={fabrics}
                          disabled={bmcAttrs.fabric.readOnly}
                        />
                      </FormField>
                    </SpaceBetween>
                  </Container>
                </div>
                <SpaceBetween size={"s"}>
                  <FormField
                    label={bmcAttrs.sessionType.label}>
                    <Select
                      selectedOption={sessionTypes.find((o) => o.value === bmcAttrs.sessionType.value) || null}
                      onChange={
                        (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
                          const newBmcAttrs = { ...bmcAttrs };
                          newBmcAttrs.sessionType.value = event.detail.selectedOption.value || sessionTypes[0].value || "shell";
                          setBmcAttrs(newBmcAttrs);
                        }
                      }
                      options={sessionTypes}
                      ariaRequired={true}
                    />
                  </FormField>
                  <FormField
                    label={bmcAttrs.caz.label}>
                    <Input
                      value={bmcAttrs.caz.value}
                      ariaRequired={true}
                      invalid={!isValidCAZ(bmcAttrs.caz.value)}
                      onChange={
                        (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                          const newBmcAttrs = { ...bmcAttrs };
                          newBmcAttrs.caz.value = event.detail.value;
                          setBmcAttrs(newBmcAttrs);
                        }
                      }
                    />
                  </FormField>
                </SpaceBetween>
                <SpaceBetween size={"s"}>
                  <FormField
                    label={"Federation Account"}>
                    <Input disabled={federationDisabled}
                           value={getFederationAccountEmail(bmcAttrs.availabilityZone.value, bmcAttrs.fabric.value)}/>
                  </FormField>
                  <FormField
                    label={"Federation Role"}>
                    <Input
                      disabled={federationDisabled}
                      value={bmcAttrs.roleName.value}
                      onChange={
                        (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                          const newBmcAttrs = { ...bmcAttrs };
                          newBmcAttrs.roleName.value = event.detail.value;
                          setBmcAttrs(newBmcAttrs);
                        }
                      }
                    />
                  </FormField>
                </SpaceBetween>
              </ColumnLayout>
            </Form>
          </Modal>


          <Table
            items={sessions}
            loading={sessionTableLoading}
            loadingText={"Loading..."}
            columnDefinitions={[
              {
                id: "sessionId",
                header: "Session ID",
                cell: item => <Link href={`/connect/${item.sessionId}`} external>{item.sessionId}</Link>
              },
              {
                id: "sessionName",
                header: "Session Name",
                cell: item => item.sessionName
              },
              {
                id: "sessionState",
                header: "Session State",
                cell: item => item.sessionState
              },
              {
                id: "hardwareId",
                header: "Hardware ID",
                cell: item => (item.sessionIsolation) ? item.hardwareId : '-'
              },
              {
                id: "assetId",
                header: "Asset ID",
                cell: item => (item.sessionIsolation) ? item.assetId : '-'
              },
              {
                id: "availabilityZone",
                header: "Availability Zone",
                cell: item => item.availabilityZone
              },
              {
                id: "fabric",
                header: "Fabric",
                cell: item => item.fabric
              },
            ]}
            empty={
              <Box
                margin={{ vertical: "xs" }}
                textAlign="center"
                color="inherit"
              >
                <SpaceBetween size="m">
                  <b>No sessions</b>
                  <Button
                    onClick={() => {
                      showConnectForm();
                    }}
                  >New session</Button>
                </SpaceBetween>
              </Box>
            }
          />
        </ContentLayout>
      }
    />
  );
}
